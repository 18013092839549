<template>
  <div class="m-3">
    <!-- <PageHeader :title="title" :items="items" /> -->

    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <!-- <h4 class="card-title">ข้อมูลลูกค้า</h4> -->

            <div class="row">
              <b-alert
                variant="success"
                show
                class="text-center mt-4 px-4 mb-0"
              >
                <i
                  class="
                    uil uil-check-circle
                    d-block
                    display-4
                    mt-2
                    mb-3
                    text-success
                  "
                ></i>
                <h5 class="text-success">สำเร็จ</h5>
                <span class="text-success"><b>ขอบคุณที่ทำแบบฟอร์มค่ะ/ครับ</b></span>

                <!-- <p>{{ data.errMsg }}</p> -->
              </b-alert>
            </div>
          </div>

          <hr />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  required,
  // email,
  minLength,
  // sameAs,
  maxLength,
  numeric,
  // url,
  // alphaNum,
} from "vuelidate/lib/validators";
// import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
// import axios from "axios";

/**
 * Form validation component
 */
export default {
  components: {
    // PageHeader,
  },
  page: {
    title: "แจ้งเตือน",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },

  data() {
    return {
      Hide: false,
      enabled: false,
      submitform: false,
      submitformAddress: false,
      modalUpdateAddress: false,
      submitUpdateAddress: false,

      title: "เพิ่มข้อมูลสำรวจตลาด",
      items: [],
      amphuresLo: [],
      localDataBranch: [], //เป็น-array Id
      localData: [],
      value: null,
      value1: null,
      dataTest: [],
      options: [],

      startIndex: "",
      endIndex: "",
      loading: undefined,
      total_pages: "",
      page: "",
      per_page: "",
      totalRecord: "",
      rowData: [], //? Data ที่ต่อ API
      // rowUser: [],
      rowAddress: [],
      currentPage: 1,
      perPage: 10,
      pageOptions: [5, 10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "age",
      sortDesc: false,
      paginatedData: "",

      selectedUsers: [],
      isSelectedAll: false,

      selectMode: "multi",
      selected: [],

      fields: [
        {
          key: "buildingNumber",
          sortable: true,
          label: "บ้านเลขที่",
        },
        {
          key: "districtNameTh",
          sortable: true,
          label: "ตำบล",
        },
        {
          key: "amphurNameTh",
          sortable: true,
          label: "อำเภอ",
        },
        {
          key: "provinceNameTh",
          sortable: true,
          label: "จังหวัด",
        },
        {
          key: "action",
          label: "แก้ไข | ลบ ",
        },
      ],

      error: "กรุณาตรวจสอบข้อมูลอีกครั้ง.",
      readinessOption: [
        { text: "สูง", value: "3" },
        { text: "กลาง", value: "2" },
        { text: "ต่ำ", value: "1" },
        { text: "ยังไม่พร้อม", value: "0" },
      ],
      lavelOption: [
        { text: "สูง", value: "3" },
        { text: "กลาง", value: "2" },
        { text: "ต่ำ", value: "1" },
        { text: "อื่นๆ", value: "0" },
      ],
      occOption: [],
      salaryOption: [
        { nameTh: "ต่ำกว่า 10,000", id: 0 },
        { nameTh: "10,001-30,000", id: 1 },
        { nameTh: "30,001-50,000", id: 2 },
        { nameTh: "50,001-100,000", id: 3 },
        { nameTh: "100,000 บาทขึ้นไป", id: 4 },
      ],
      districtsLo: [],
      cusSrcOption: [],
      prefixOPtion: [],
      provinces: [],
      amphures: [],
      districts: [],
      residentOption: [
        { nameTh: "เช่า", id: "R" },
        { nameTh: "อาศัยอยู่บ้านตัวเอง", id: "H" },
        { nameTh: "อาศัยอยู่กับญาติ", id: "RH" },
        { nameTh: "อาศัยอยู่กับผู้อื่น", id: "OH" },
      ],
      maritalOption: [
        { nameTh: "โสด", id: "S" },
        { nameTh: "แต่งงานแล้ว", id: "M" },
        { nameTh: "หย่าร้าง", id: "D" },
        { nameTh: "หม้าย", id: "W" },
      ],
      genderOption: [
        { nameTh: "ชาย", id: "M" },
        { nameTh: "หญิง", id: "F" },
        { nameTh: "ไม่ระบุ", id: "NONE" },
      ],
      salarysOption: [],
      eventOption: [],
      srcInOption: [],
      usedBrandOption: [],
      lifestyleOption: [],
      objOption: [],
      familySalaryOption: [],
      croOption: [],
      otherModelOption: [],
      otherVehicleOption: [],
      otherVehicleSubOption: [],
      usedVehicleOption: [],
      durationVehicleOption: [
        { nameTh: "ภายใน 1-2 เดือน", id: "1" },
        { nameTh: "ภายใน 3-4 เดือน", id: "2" },
        { nameTh: "ออกรถไปแล้ว/ยกเลิกจอง/ยกเลิกส่งมอง", id: "3" },
        { nameTh: "ยังไใ่ได้ตัดสินใจ", id: "4" },
      ],
      regPlaceOption: [{ nameTh: "Showroom " }, { nameTh: "Road Show" }],
      hideButton: false,
      campOption: [],
      durationOption: [],
      objecOption: [],
      factorOption: [],
      param: this.$route.query,
      form: {
        lineId: "",
        facebookId: "",

        branchId: "",
        prefixId: "",
        nameTh: "",
        familyNameTh: "",
        mobilePhone: "",
        note: "",
        postCode: "",
        lane: "",
        subLane: "",
        townshipName: "",
        townshipNumber: "",
        buildingNumber: "",
        occId: "",
        eventId: "",
        srcInfoId: "",
        familySalaryId: "",
        gender: "",
        age: "",
        usedBrand: "",
        usedVehicleTypeId: "",
        purposeId: "",
        reasonId: "",
        duration: "",
        otherVehicleTypeId: "",
        otherBrandId: "",
        factorId: "",
        campId: "",
        testDrive: "",
        brandEventId: "",
        otherEventId: "",
        showroom: "",
        hobby: "",
        peopleAdvice: "",
        familyMembers: "",
        date: "",
        amphurLocationId: "",
        locationName: "",
        provinceLocationId: "",
        sale: "",
        districtIdLocationId: "",
      },
      provinceId: "",
      amphurId: "",
      districtId: "",
    };
  },
  validations: {
    form: {
      // branchId: {
      //   required,
      // },
      nameTh: {
        required,
      },

      mobilePhone: {
        // required,
        numeric,
        maxLength: maxLength(10),
        minLength: minLength(10),
      },

      postCode: {
        numeric,
        minLength: minLength(5),
        maxLength: maxLength(5),
      },
    },
  },
  mounted() {},
  computed: {
    fullAddress: function () {
      //

      return `${
        this.form.buildingNumber != ""
          ? "" + this.form.buildingNumber
          : this.form.buildingNumber
      } ${
        this.form.townshipNumber != ""
          ? "หมู่" + " " + this.form.townshipNumber
          : this.form.townshipNumber
      } ${
        this.form.townshipName != ""
          ? "" + this.form.townshipName
          : this.form.townshipName
      } ${
        this.form.subLane != ""
          ? "ตรอก" + " " + this.form.subLane
          : this.form.subLane
      } ${
        this.form.lane != "" ? "ซอย" + " " + this.form.lane : this.form.lane
      } ${
        this.form.streetName != "" && this.form.streetName != undefined
          ? "ถนน" + " " + this.form.streetName
          : ""
      } ${
        this.districtId != "" &&
        this.districtId != undefined &&
        this.districtId != null &&
        this.districtId.nameTh != "" &&
        this.districtId.nameTh != undefined &&
        this.districtId.nameTh != null
          ? this.districtId.nameTh
          : ""
      } ${
        this.amphurId != "" &&
        this.amphurId != undefined &&
        this.amphurId != null &&
        this.amphurId.nameTh != "" &&
        this.amphurId.nameTh != undefined &&
        this.amphurId.nameTh != null
          ? this.amphurId.nameTh
          : ""
      } ${
        this.provinceId != "" &&
        this.provinceId != null &&
        this.provinceId != undefined &&
        this.provinceId.nameTh != null &&
        this.provinceId.nameTh != "" &&
        this.provinceId.nameTh != undefined
          ? this.provinceId.nameTh
          : ""
      }  ${
        this.form.postCode != ""
          ? "รหัสไปรษณีย์" + " " + this.form.postCode
          : ""
      }
      `;
    },
  },
  created() {},
  methods: {
    formatDate(date) {
      var d = new Date(date),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;

      return [year, month, day].join("-");
    },
    validateNextTab(props) {
      this.$v.$touch();
      this.submitform = true;
      if (this.$v.form.$invalid != true) {
        props.nextTab();
      }
    },
    customLabelCro({ nameTh, familyName }) {
      return `${nameTh}  ${familyName ? familyName : ""}`;
    },
    customLabel({ nameTh, familyName }) {
      return `${nameTh}  ${familyName ? familyName : ""}`;
    },
    max() {
      const now = new Date();
      const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
      const maxDate = new Date(today);

      maxDate.setDate(0);
      // console.log(maxDate);
    },
    ChangeNameTh(nameTh) {
      if (nameTh.nameTh === "นาย") {
        this.form.prefixIdEn = "Mr.";
      } else if (nameTh.nameTh === "นาง") {
        this.form.prefixIdEn = "Mrs.";
      } else {
        this.form.prefixIdEn = "Miss.";
      }
    },

    getLocalData() {
      const user = JSON.parse(localStorage.getItem(appConfig.storageUserKey));
      this.localData = user.userAccessBranch;
      this.branchItem = user.branchId;

      const localDataBranch = [];
      this.localData.forEach((item) => {
        localDataBranch.push(item.branchId);
      });

      this.localDataBranch = localDataBranch;
    },

    Form() {
      this.$v.$touch();
      this.submitform = true;
      if (this.$v.form.$invalid != true) {
        this.postMarketScan();
      }
    },

    /**
     * Search the table data with search input
     */
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.rowAddress = filteredItems.length;
      this.currentPage = 1;
    },
  },
};
</script>
<style scoped>
.input-placeholder {
  font-size: 12px;
}
.br {
  padding-right: 20px;
}
</style>

import { render, staticRenderFns } from "./messge-success.vue?vue&type=template&id=4a4252a0&scoped=true"
import script from "./messge-success.vue?vue&type=script&lang=js"
export * from "./messge-success.vue?vue&type=script&lang=js"
import style0 from "./messge-success.vue?vue&type=style&index=0&id=4a4252a0&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4a4252a0",
  null
  
)

export default component.exports